type HelpPageMap = {
  [key: string]: {
    [key: string]: string;
  };
};

export const useHelpConfig = () => {
  const HELP_PAGE_MAP: HelpPageMap = {
    prod1: {
      learner_understandingData: '4410819490066-Understanding-the-data',
      teacher_understandingData: '4410743080210-Understanding-the-data',
      learner_practiceTestScores:
        '4411089956882-Understanding-your-Test-Train-Timed-Practice-Test-scores',
      teacher_practiceTestScores:
        '4410612486162-Understanding-Test-Train-Timed-Practice-Test-scores',
      teacher_understandingCodesAndKeys:
        '19767349810322-What-is-the-difference-between-activation-codes-class-keys-and-school-keys',
      teacher_activatingCourseMaterials:
        '13444112644498-How-do-I-activate-my-teaching-materials-on-Cambridge-One',
      teacher_creatingClass: '4410631715346-How-do-I-create-a-class',
      teacher_addingStudentsToClass:
        '4410612035602-How-do-I-add-adult-students-to-a-class',
      teacher_creatingAssignments:
        '4410612256274-How-do-I-set-homework-assignments-on-Cambridge-One',
      ielts: '4410106166930-IELTS-Intelligence'
    },
    default: {
      learner_understandingData: '4410848542226-Understanding-the-data',
      teacher_understandingData: '4405381750162-Understanding-the-data',
      learner_practiceTestScores:
        '4411124450194-Understanding-your-Test-Train-Timed-Practice-Test-scores',
      teacher_practiceTestScores:
        '4405380464274-Understanding-your-Test-Train-Timed-Practice-Test-scores',
      teacher_understandingCodesAndKeys:
        '20391916463634-What-is-the-difference-between-an-activation-code-class-key-and-school-key',
      teacher_activatingCourseMaterials:
        '17760467863186-How-do-I-activate-my-teaching-materials-on-Cambridge-One',
      teacher_creatingClass: '4405381295890-How-do-I-create-a-class',
      teacher_addingStudentsToClass:
        '4405387421714-How-do-I-add-adult-students-to-a-class',
      teacher_creatingAssignments:
        '4405387512466-How-do-I-set-homework-assignments-on-Cambridge-One',
      ielts: '4407581188498-IELTS-Intelligence'
    }
  };

  /* Get the Help Page property */
  const getHelpPagePath = (
    environment: string,
    category?: string,
    article?: string,
    language?: string
  ) => {
    let helpPagePath =
      language === 'En'
        ? useRuntimeConfig().public.zendeskBasePathEN
        : useRuntimeConfig().public.zendeskBasePathES;
    if (category) {
      if (article) {
        // @ts-ignore
        const articlePath = (HELP_PAGE_MAP[environment] ||
          HELP_PAGE_MAP.default)[`${category}_${article}`];
        if (articlePath) helpPagePath += '/articles/' + articlePath;
      } else {
        // @ts-ignore
        const categoryPath = (HELP_PAGE_MAP[environment] ||
          HELP_PAGE_MAP.default)[category];
        if (categoryPath) helpPagePath += '/categories/' + categoryPath;
      }
    }
    return helpPagePath;
  };

  return { getHelpPagePath };
};
